import Page from '../components/Page'
import Section from '../components/Section'
import ContactCards from '../components/ContactCards'

const pageTitle = 'neomento - 404'

const disclaimer = 'Leider wird diese Seite nicht gefunden.'

export default function Custom404() {
    return (
        <Page title={pageTitle}>
            <Section>
                <div className="flex flex-col items-center">
                    <img
                        className="w-36 mb-14"
                        src="/404.svg"
                        alt="404"
                    />
                    <p className="tex-grayDarkLight uppercase text-sm text-center">{disclaimer}</p>
                    <div className="mt-28 sm:w-full lg:w-2/3 xl:w-2/4">
                        <ContactCards />
                    </div>
                </div>
            </Section>
        </Page>
    )
}
