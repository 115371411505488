const ContactCards = () =>
    <div className="flex flex-col gap-x-4 gap-y-4 sm:flex-row mt-20 sm:mt-32 mb-10 sm:mb-0">
        <div className="flex-1 px-8 md:px-8 py-8 bg-lightGray rounded-3xl flex flex-col items-center">
            <img className="pb-5 sm:pb-6 h-14" src="/contact/phone.svg" alt="anrufen" />
            <div className="text-center text-grayDarkLight hover:underline">
                <a href="tel:004930549072751">030 - 5490 7275 0</a>
            </div>
        </div>
        <div className="flex-1 px-8 md:px-8 py-8 bg-lightGray rounded-3xl flex flex-col items-center">
            <img className="pb-5 sm:pb-6 h-14" src="/contact/email.svg" alt="mailen" />
            <div className="text-center text-grayDarkLight hover:underline">
                <a href="mailto:hello@neomento.de">hello@neomento.de</a>
            </div>
        </div>
    </div>


export default ContactCards